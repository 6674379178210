import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationRules } from 'aurelia-validation';
import { isEmptyGuid } from '@dts/scriptlib';
import { AuthService } from 'services/auth-service';
import { IAuthToken } from 'services/state/state';

@autoinject()
export class Login {
    public errors = [];
    public success = [];

    public userName: string = '';
    public password: string = '';

    private isBusy = false;

    private userNameHasFocus = false;
    private passwordHasFocus = false;

    private isBrowserSupported = true;

    constructor(private readonly router: Router, private readonly controller: BootstrapValidationController, private readonly authService: AuthService) {
        ValidationRules
            .ensure('userName').required()
            .ensure('password').required()
            .on(this);
    }

    attached() {
        const isChromium = window['chrome'];
        const winNav = window.navigator;
        const vendorName = winNav.vendor;
        const isOpera = winNav.userAgent.indexOf("OPR") > -1;
        const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
        const isSafari = winNav.userAgent.indexOf("Safari") > -1;

        const isIOSChrome = winNav.userAgent.match("CriOS");

        let browser = '';

        if (isChromium !== null && isChromium !== undefined && vendorName === "Google Inc." && isOpera == false && isIEedge == false) {
            browser = 'chrome';
        } else if (isSafari) {
            browser = 'safari';
        } else if (isIEedge) {
            browser = 'edge';
        } else {
            browser = 'not supported';
            this.isBrowserSupported = false;
        }
    }

    public activate(params, routeConfig, navigationInstruction) {
        if (params.username) {
            this.userName = params.username;
        } else if (params.email) {
            this.userName = params.email;
        } else {
            const authToken: IAuthToken = JSON.parse(localStorage.getItem('authToken'));
            if (authToken?.currentUser?.userName) {
                this.userName = authToken.currentUser.userName;
            }
        }

        queueMicrotask(() => {
            if (this.userName.length > 0) {
                this.userNameHasFocus = false;
                this.passwordHasFocus = true;
            } else {
                this.passwordHasFocus = false;
                this.userNameHasFocus = true;
            }
        });
    }

    public async submit() {

        this.success.splice(0);
        this.errors.splice(0);

        const validate = await this.controller.validate();

        if (validate.valid) {
            this.isBusy = true;
            const result = await this.authService.login(this.userName, this.password);

            if (result.ok) {
                const currentPharmacyGuid = this.authService.currentPharmacyGuid();
                if (isEmptyGuid(currentPharmacyGuid)) {
                    console.log('login, navigateToRoute, choosePharmacy');
                    this.router.navigateToRoute('choosePharmacy');

                } else {
                    console.log('login, navigateToRoute, blister');
                    this.router.navigateToRoute('blister');
                }
            } else {

                this.success.splice(0);
                this.errors.splice(0);

                if (result.errors.findIndex(e => e.code == 'ChangePassword') >= 0) {
                    this.router.navigateToRoute('changePassword', { username: this.userName });
                } else {

                    result.errors.forEach((e) => {
                        let description = e.description;
                        if (e.code === 'EmailNotConfirmed') {
                            const email = this.userName;
                            description = `<div>${description}<a class="nav-link" route-href="route: registerEmailSend; params.bind: { email: '${email}' }">Click here to send a confirmation email.</a></div>`;
                        }
                        this.errors.push(description);
                    });
                }
            }
            this.isBusy = false;
        }
    }
}
