import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { AuthService } from 'services/auth-service';

@autoinject()
export class Logout {

    timeout;
    constructor(private readonly authService: AuthService, private readonly router: Router) {
    }

    activate(params, routeConfig, navigationInstruction) {
        const userName = this.authService.currentUser().userName;
        this.timeout = setTimeout(() => this.router.navigateToRoute('login', { username: userName }), 2000);
        return this.authService.logout();
    }

    deactivate() {
        clearTimeout(this.timeout);
    }
}
