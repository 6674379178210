import { GenericEntitySearch } from '@resources/utils/generic-entity-search';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ImpiloDb, ResultPager } from 'plugins/data-models';
import { AuthService } from 'services/auth-service';
import { ImpiloRepository } from 'services/repositories';
import { careHomeSelectedRowAction, pharmacyChanged } from 'services/state/actions';
import { ToastrService } from 'services/toastr-service';

@autoinject()
export class Pharmacies {

    @bindable pharmacies = [];
    @bindable searchTerm: string;

    constructor(private readonly repository: ImpiloRepository, private readonly router: Router, private readonly authService: AuthService, private readonly toastr: ToastrService) {
    }

    async bind(params, routeConfig, navigationInstruction) {

        const currentUser = this.authService.currentUser();
        const response = await this.repository.getPharmacy(null, currentUser.user);
        this.pharmacies = response.results;
    }

    clearFilter() {
        this.searchTerm = '';
    }

    filterFunc(searchExpression, value) {

        const itemValue = value.name;
        if (!searchExpression || !itemValue) return false;

        return itemValue.toUpperCase().indexOf(searchExpression.toUpperCase()) !== -1;

    }

    async activatePharmacy(event) {
        await pharmacyChanged(event.pharmacy);

        localStorage.setItem('pharmacy', event.pharmacy.guid); //HACK

        this.toastr.info(`Active pharmacy is changed to ${event.pharmacy.name}`);
    }
}

export class FilterValueConverter {
    toView(array, searchTerm, filterFunc) {
        return array.filter((item) => {
            return searchTerm && searchTerm.length > 0 ? filterFunc(searchTerm, item) : true;
        });
    }
}
