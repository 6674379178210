import 'aurelia-slickgrid/dist/styles/css/slickgrid-theme-bootstrap.css';
import 'bootstrap';
import 'aurelia-bootstrap-select/node_modules/bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import 'flatpickr/dist/flatpickr.min.css';
import 'dialog-polyfill';

import '../static/animations.css';
import '../static/bootstrap-datetimepicker-bs4.css';
import '../static/bootstrap-float-label.css';
import '../static/offcanvas.css';
import '../static/site.css';

import { HttpClient, HttpClientConfiguration } from 'aurelia-fetch-client';
import { Aurelia } from 'aurelia-framework';
import { TCustomAttribute } from 'aurelia-i18n';
import { PLATFORM } from 'aurelia-pal';
import Backend from 'i18next-xhr-backend';
import { Fetch401Interceptor, FetchAuthorizationInterceptor, FetchDebugInterceptor, FetchNotOkInterceptor } from 'plugins/data-models';
import { IdentityRepository, ImpiloRepository } from 'services/repositories';
import { UrlService } from 'services/url-service';
import * as environment from '../config/environment.json';
import { initialState } from '../src/services/state/state';
import * as signalR from "@microsoft/signalr";

import {
  Chart, LineController, LineElement, PointElement, LinearScale, Title, DoughnutController, PieController, CategoryScale, ArcElement,
  Legend
} from 'chart.js';
import { HubService } from 'services/hub-service';

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, DoughnutController, PieController, CategoryScale, ArcElement, Legend);

export async function configure(aurelia: Aurelia) {
  aurelia.use
    .standardConfiguration()
    .plugin(PLATFORM.moduleName('aurelia-animator-css'))
    .plugin(PLATFORM.moduleName('aurelia-validation'))
    .plugin(PLATFORM.moduleName('aurelia-dialog'), (config) => {
        config.useRenderer('native');
    })
    .plugin(PLATFORM.moduleName('aurelia-ui-virtualization'))
    .plugin(PLATFORM.moduleName('aurelia-i18n'), (instance) => {
      const aliases = ['t', 'i18n'];
      // add aliases for 't' attribute
      TCustomAttribute.configureAliases(aliases);

      // register backend plugin
      instance.i18next.use(Backend);

      // adapt options to your needs (see http://i18next.com/docs/options/)
      // make sure to return the promise of the setup method, in order to guarantee proper loading
      return instance.setup({
        backend: {
          loadPath: './locales/{{lng}}/{{ns}}.json',
        },
        // tslint:disable-next-line: object-literal-sort-keys
        attributes: aliases,
        lng: 'en',
        fallbackLng: 'en',
        debug: false,
        skipTranslationOnMissingKey: true,
      });
    })
    .plugin(PLATFORM.moduleName('aurelia-slickgrid'))
    .plugin(PLATFORM.moduleName('aurelia-bootstrap-select'), config => {
      config.options.liveSearch = true;
      config.options.liveSearchNormalize = true;
      config.options.size = 10;
      config.options.style = '';
      config.options.styleBase = 'form-control';
      config.options.width = '100%';
    })
    .plugin(PLATFORM.moduleName('aurelia-chart'));

  aurelia.use
    .feature(PLATFORM.moduleName('features/autocomplete/index'))
    .feature(PLATFORM.moduleName('features/dynamic-html/index'))
    .feature(PLATFORM.moduleName('features/offcanvas/index'))
    .feature(PLATFORM.moduleName('features/markdown/index'))
    .feature(PLATFORM.moduleName('resources/index'))
    .feature(PLATFORM.moduleName('plugins/data-models/index'))
    .feature(PLATFORM.moduleName('plugins/admin/index'))
    .feature(PLATFORM.moduleName('plugins/blister/index'))
    .feature(PLATFORM.moduleName('plugins/carehome/index'))
    .feature(PLATFORM.moduleName('resources/index'))
    .feature(PLATFORM.moduleName('plugins/blister/routes/patients/patient/medications/medication/quick/index'))
    .feature(PLATFORM.moduleName('plugins/blister/routes/account/dialog/index'))
    .feature(PLATFORM.moduleName('plugins/blister/routes/print/dialog/index'))
    .feature(PLATFORM.moduleName('plugins/blister/routes/patients/patient/medications/place-order/dialog/index'));



  aurelia.use.developmentLogging(environment.debug ? 'debug' : 'warn');

  if (environment.testing) {
    aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
  }

  await registerRepository(aurelia);

  aurelia.use.plugin(PLATFORM.moduleName('aurelia-store'), { initialState });

  await aurelia.start();

  return aurelia.setRoot(PLATFORM.moduleName('app'));
}

async function registerRepository(aurelia: Aurelia) {

  const impilo = new ImpiloRepository(applyHttpClient(aurelia, `${await aurelia.container.get(UrlService).impiloUrl()}`)); // , 'application/json'));
  aurelia.container.registerInstance(ImpiloRepository, impilo);

  const identity = new IdentityRepository(applyHttpClient(aurelia, `${await aurelia.container.get(UrlService).identityUrl()}`)); // , 'application/json'));
  aurelia.container.registerInstance(IdentityRepository, identity);

  const hubService = new HubService(aurelia.container.get(UrlService));
  aurelia.container.registerInstance(HubService, hubService);
  hubService.ensure();
}

function applyHttpClient(aurelia: Aurelia, url: string, contentType?: string): HttpClient {

  let headers = {
    'Accept': 'application/json',
  };

  if (contentType) {
    headers = { ...headers, ...{ 'Content-Type': contentType } };
  }

  return aurelia.container.invoke(HttpClient).configure((config: HttpClientConfiguration) => {
    config
      .withBaseUrl(url)
      .withDefaults({
        headers
      })
      .withInterceptor(new FetchAuthorizationInterceptor())
      .withInterceptor(new FetchNotOkInterceptor())
      .withInterceptor(new Fetch401Interceptor())
      // .withInterceptor(new FetchDebugInterceptor())
      .useStandardConfiguration();
    // .withRetry();
  });
}
