import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

@autoinject()
export class ResetPasswordConfirmation {

    public params: Partial<{ email: string }> = {};

    constructor(private readonly router: Router) {
    }

    public activate(params, routeConfig, navigationInstruction) {
        if (params.email) {
            this.params.email = params.email;
        }
    }
}
