import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { connectTo } from 'aurelia-store';
import { pluck } from 'rxjs/operators';
import { AuthService } from 'services/auth-service';
import { IState } from 'services/state/state';

@connectTo<IState>({
    selector: {
        authToken: (store) => store.state.pipe(pluck('authToken')),
        currentPharmacy: (store) => store.state.pipe(pluck('plugins', 'blister', 'currentPharmacy')),
    }
})
@autoinject()
export class NavBar {
    public routes = [];
    public isAuthenticated: boolean = false;

    constructor(public router: Router, private readonly authService: AuthService) {

    }

    public attached() {
        this.routes = this.router.navigation;
        this.changeRoutes();
    }

    public showNav(navItem) {
        console.log('showNav: ' + navItem.config.name);

        if (!navItem.config.roles) {
            console.log('no roles define, return true');
            return true;
        }

        const enable: boolean = this.authService.isAuthorized(navItem.config.roles);
        console.log(navItem.config.name + ' ' + (enable ? 'enabled' : 'disabled'));

        return enable;
    }

    public login() {
        return this.router.navigateToRoute('login');
    }

    public logout() {
        return this.router.navigateToRoute('logout');
    }

    public get currentUser() {
        return this.authService.currentUser();
    }

    public get isGlobalAdmin() {
        return this.authService.isGlobalAdmin();
    }

    // public stateChanged(newState, oldState) {
    //     if (oldState) {
    //         this.isAuthenticated = this.authService.isAuthenticated();
    //         this.changeRoutes();
    //     }
    // }

    authTokenChanged(newState, oldState) {
        // console.log('authTokenChanged', newState, oldState);
        this.isAuthenticated = this.authService.isAuthenticated();
        this.changeRoutes();
    }

    // currentPharmacyChanged(newState, oldState) {
    //     console.log('currentPharmacyChanged', newState, oldState);
    // }

    private changeRoutes() {
        this.routes = [];
        this.routes = this.router.navigation;
    }
}
