import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationRules } from 'aurelia-validation';
import { IdentityRepository } from 'services/identity-repository';
import { resetState } from 'services/state/actions';

@autoinject()
export class ForgotPassword {
    public errors = [];
    public success = [];
    public result;

    public email: string = '';
    public token: string = '';
    public password: string = '';
    public confirmPassword: string = '';

    private isBusy = false;

    constructor(private readonly router: Router, private readonly controller: BootstrapValidationController, private readonly repository: IdentityRepository) {

        ValidationRules
            .ensure('email').required().email()
            .ensure('token').required()
            .ensure('password').required().minLength(6)
            .ensure('confirmPassword').required().satisfiesRule('matchesProperty', 'password')
            .on(this);
    }

    async activate(params, routeConfig, navigationInstruction) {
        if (params && params.email && params.token) {

            this.email = params.email;
            this.token = params.token;
            return resetState();
        }
    }

    async submit() {
        const validate = await this.controller.validate();

        if (validate.valid) {
            this.isBusy = true;
            const result = await this.repository.resetPassword(this.email, this.token, this.password);

            if (!result.ok) {
                this.success.splice(0);
                this.errors.splice(0);
                result.errors.forEach((e) => {
                    const description = e.description;
                    this.errors.push(description);
                });
            } else {
                const params = {
                    email: this.email
                };
                this.router.navigateToRoute('resetPasswordConfirmation', params);
            }
            this.isBusy = false;
        }

    }

}
