import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration } from 'aurelia-router';

export class Manage {
    public router: Router;

    public configureRouter(config: RouterConfiguration, router: Router): void {
        this.router = router;
        config.title = '';
        config.map([
            { route: '', redirect: 'modules' },
            { route: 'modules', name: 'modules', moduleId: PLATFORM.moduleName('../modules/index'), nav: false, title: 'Modules', settings: { auth: true } },
            { route: 'profile', name: 'profile', moduleId: PLATFORM.moduleName('./manage/profile'), nav: true, title: 'Profile', settings: { t: 'account.profile' } },
            { route: 'change-password', name: 'changePassword', moduleId: PLATFORM.moduleName('./manage/change-password'), nav: true, title: 'Change Password', settings: { t: 'account.changePassword' } }
        ]);
    }
}
