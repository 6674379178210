import { autoinject } from 'aurelia-framework';
import { IdentityRepository } from 'services/identity-repository';

@autoinject()
export class RegisterEmailSend {
    public errors = [];
    public success = [];

    public email: string = 'You';

    constructor(private readonly repository: IdentityRepository) {
    }

    public async activate(params, routeConfig, navigationInstruction) {
        if (params.email) {
            this.email = params.email;

            const result = await this.repository.sendConfirmEmail(this.email);
            if (!result.ok) {
                this.success.splice(0);
                this.errors.splice(0);
                result.errors.forEach((e) => {
                    const description = e.description;
                    this.errors.push(description);
                });
            }
        }
    }
}
