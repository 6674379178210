import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationRules } from 'aurelia-validation';
import { AuthService } from 'services/auth-service';

@autoinject()
export class ChangePassword {
    errors = [];
    success = [];

    userName: string = '';
    currentPassword: string = '';
    password: string = '';
    confirmPassword: string = '';

    private isBusy = false;
    private showLoginRedirect = false;

    constructor(private readonly router: Router, private readonly controller: BootstrapValidationController, private readonly authService: AuthService) {

        ValidationRules
            .ensure('currentPassword').required()
            .ensure('password').required().minLength(6)
            .ensure('confirmPassword').required().satisfiesRule('matchesProperty', 'password')
            .on(this);
    }

    activate(params, routeConfig, navigationInstruction) {
        if (params.username) {
            this.userName = params.username;
            this.showLoginRedirect = true;
        } else {
            this.userName = this.authService.currentUser().userName;
        }
    }

    async submit() {

        this.success.splice(0);
        this.errors.splice(0);

        const validate = await this.controller.validate();

        if (validate.valid) {
            this.isBusy = true;
            const result = await this.authService.changePassword(this.userName, this.currentPassword, this.password);
            this.isBusy = false;

            if (result.ok) {
                this.success.splice(0);
                this.errors.splice(0);

                if (this.showLoginRedirect) {
                    this.router.navigateToRoute('login', { username: this.userName });
                } else {
                    this.success.push('You password is now changed.');
                }
            } else {
                this.success.splice(0);
                this.errors.splice(0);
                result.errors.forEach((e) => {
                    this.errors.push(e.description);
                });
            }
        }
    }
}
