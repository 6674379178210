import { autoinject, bindable, observable } from 'aurelia-framework';
import { ImpiloDb } from 'plugins/data-models';
import { ImpiloRepository } from 'services/repositories';

@autoinject()
export class Welcome {
    public DTS_Logo = 'DTS_Logo.png';

    // private abortController = new AbortController();

    // inputValue = 'welcome';

    // constructor(private readonly repository: ImpiloRepository) {
    // }

    // onSelected(event) {
    //     console.log(event.detail.name);
    // }

    // medicationSource = async (term, suggest) => {
    //     if (this.abortController && !this.abortController.signal.aborted) {
    //         this.abortController.abort();
    //     }
    //     this.abortController = new AbortController();
    //     const response = await (new ImpiloDb.Tables.Medication().fulltext(this.repository, term, 1, 50, this.abortController.signal));
    //     const suggestions = response?.results.map(s => {
    //         s['val'] = s.name;
    //         return s;
    //     }) ?? [];
    //     suggest(suggestions);
    // }
}
