import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { autoinject, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Store } from 'aurelia-store';
import { ValidationRules } from 'aurelia-validation';
import { get, set, clear } from 'idb-keyval';
import { IApiResult } from 'plugins/data-models';
import { Subscription } from 'rxjs';
import { AuthService } from 'services/auth-service';
import { HubService, hubConnectionId } from 'services/hub-service';
import { IdentityRepository, ImpiloRepository } from 'services/repositories';
import { resetState } from 'services/state/actions';
import { IState } from 'services/state/state';
import { ToastrService } from 'services/toastr-service';
import { UrlService } from 'services/url-service';

@autoinject()
export class Settings {

    @observable impiloUrl = '';
    @observable identityUrl = '';
    @observable hubUrl = '';

    private state: IState;
    private sub: Subscription;

    private pingIndentityStatus: string = '';
    private pingImpiloStatus: string = '';

    private pingResult: IApiResult;

    appVersion: AppVersion;
    name: string;
    version: string;
    buildDate: string;
    buildGitHash: string;

    locales: { title: string, code: string }[];
    _selectedLocale: { title: string, code: string };

    constructor(private readonly controller: BootstrapValidationController, private readonly store: Store<IState>,
        private readonly identityRepository: IdentityRepository,
        private readonly impiloRepository: ImpiloRepository,
        private readonly toastr: ToastrService,
        private readonly authService: AuthService,
        private readonly i18n: I18N,
        private readonly urlService: UrlService,
        private readonly hubService: HubService) {

        ValidationRules
            .ensure('impiloUrl').required()
            .ensure('identityUrl').required()
            .ensure('hubUrl').required()
            .on(this);
    }

    get selectedLocale(): { title: string, code: string } {
        return this._selectedLocale;
    }

    set selectedLocale(newValue: { title: string, code: string }) {
        if (newValue) {
            this._selectedLocale = newValue;
            set('selectedLocale', newValue).then((value) => {
                this.i18n.setLocale(newValue.code);
            } );
        }
    }

    bind() {
        this.sub = this.store.state.subscribe({
            next: state => this.state = state
        });
    }

    unbind() {
        this.sub.unsubscribe();
    }

    async activate(params, routeConfig, navigationInstruction) {
        this.impiloUrl =  await this.urlService.impiloUrl();
        this.identityUrl = await this.urlService.identityUrl();
        this.hubUrl = await this.urlService.hubUrl();

        const response = await fetch('locales.json');
        const json = await response.json();
        this.locales = json.map(v => v);

        this.selectedLocale = await get('selectedLocale');
        if (!this.selectedLocale) {
            this.selectedLocale = this.locales.find(f => f.code === 'en');
        }
    }

    async attached() {
        await this.versionInfo();
        await this.pingIdentityUrl();
        await this.pingImpiloUrl();
    }

    async identityUrlChanged(newValue: string) {
        if (newValue && newValue.length > 0) {
            this.identityRepository.setBaseUrl(newValue);
            await set('identityUrl', newValue);
            await this.pingIdentityUrl();
        }
    }

    async impiloUrlChanged(newValue: string) {
        if (newValue && newValue.length > 0) {
            this.impiloRepository.setBaseUrl(newValue);
            await set('impiloUrl', newValue);
            await this.pingImpiloUrl();
        }
    }

    async hubUrlChanged(newValue: string) {
        if (newValue && newValue.length > 0) {
            await set('hubUrl', newValue);
            this.hubService.echo(hubConnectionId);
        }
    }

    async reset() {
        await resetState();
        await this.authService.logout();
        await clear();
    }

    async pingIdentityUrl() {
        this.pingResult = await this.identityRepository.ping();
        this.pingIndentityStatus = this.pingResult.ok ? 'Online' : 'Offline';
    }

    async pingImpiloUrl() {
        this.pingResult = await this.impiloRepository.ping();
        this.pingImpiloStatus = this.pingResult.ok ? 'Online' : 'Offline';
    }

    async versionInfo(): Promise<void> {
        const response = await fetch('version.json');
        const json = await response.json();

        this.appVersion = { ...json };
        this.name = this.appVersion.name;
        this.version = this.appVersion.version;
        this.buildDate = this.appVersion.buildDate;
        this.buildGitHash = this.appVersion.hash;
    }

    private readonly codeMatcher = (a, b) => {
        if (a && b) {
            return a.code === b.code;
        }

        return false;
    }

}

interface AppVersion {
    /** application name as specified in package.json */
    readonly name: string;


    /** build timestamp in milliseconds since the epoch */
    readonly timestamp: number;

    readonly buildDate: string;

    readonly buildDateShort: string;

    /** application version as specified in package.json */
    readonly version: string;

    /** number of commits in the Git repo */
    readonly numCommits: number;

    /** latest Git commit hash */
    readonly hash: string;

    /** flag is set when uncommitted or untracked changes are present in the workspace */
    readonly dirty: boolean;
}
