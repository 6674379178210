import { autoinject } from 'aurelia-framework';
import { IdentityRepository } from 'services/identity-repository';

@autoinject()
export class EmailConfirmed {
    public errors = [];
    public success = [];

    public params: Partial<{ username: string }> = {};

    constructor(private readonly repository: IdentityRepository) {
    }

    public async activate(params, routeConfig, navigationInstruction) {
        if (params.email && params.token) {
            this.params.username = params.email;

            const result = await this.repository.confirmEmail(params.email, params.token);
            if (!result.ok) {
                this.success.splice(0);
                this.errors.splice(0);
                result.errors.forEach((e) => {
                    const description = e.description;
                    this.errors.push(description);
                });
            } else {
                this.success.splice(0);
                this.errors.splice(0);
                this.success.push('Thank you for confirming your email.');
            }
        }
    }
}
