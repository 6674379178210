import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationRules } from 'aurelia-validation';
import { IdentityRepository, RegisterModel } from 'services/identity-repository';

@autoinject()
export class Register {
    errors = [];

    email: string = '';
    userName: string = '';
    password: string = '';
    confirmPassword: string = '';

    pharmacyName = '';
    bHFNumber = '';
    vATRegistrationNumber = '';
    contactPerson = '';
    telephoneNumber = '';
    faxNumber = '';
    cellPhoneNumber = '';
    emailAddress = '';
    addressLine1 = '';
    addressLine2 = '';
    suburb = '';
    city = '';
    postalCode = '';

    private isBusy = false;

    private verified = null;
    private showreCaptcha = false;

    constructor(private readonly router: Router, private readonly controller: BootstrapValidationController, private readonly repository: IdentityRepository) {

        ValidationRules
            .ensure('email').required().email()
            .ensure('password').required().minLength(6)
            .ensure('confirmPassword').required().satisfiesRule('matchesProperty', 'password')
            .ensure('pharmacyName').required()
            .ensure('bHFNumber').required().satisfiesRule('int')
            .ensure('contactPerson').required()
            .ensure('telephoneNumber').required()
            .ensure('faxNumber').required()
            .ensure('emailAddress').required().email()
            .ensure('addressLine1').required()
            .ensure('city').required()
            .ensure('postalCode').required().satisfiesRule('int')
            .on(this);
    }

    async submit() {

        const validate = await this.controller.validate();
        if (validate.valid) {

            this.showreCaptcha = true;

            if (this.verified) {
                this.userName = this.email;

                const parameters: RegisterModel = {
                    userName: this.userName,
                    password: this.password,
                    email: this.email,
                    pharmacyName: this.pharmacyName,
                    bHFNumber: this.bHFNumber,
                    vATRegistrationNumber: this.vATRegistrationNumber,
                    contactPerson: this.contactPerson,
                    telephoneNumber: this.telephoneNumber,
                    faxNumber: this.faxNumber,
                    cellPhoneNumber: this.cellPhoneNumber,
                    emailAddress: this.emailAddress,
                    addressLine1: this.addressLine1,
                    addressLine2: this.addressLine2,
                    suburb: this.suburb,
                    city: this.city,
                    postalCode: this.postalCode
                };

                this.isBusy = true;
                const result = await this.repository.register(parameters);

                if (!result.ok) {
                    this.errors.splice(0);
                    result.errors.forEach((e) => {
                        this.errors.push(e.description);
                    });
                } else {
                    const params = {
                        email: this.email
                    };
                    this.router.navigateToRoute('registerEmailSend', params);
                }

                this.isBusy = false;
            } else {

                this.errors.splice(0);
                this.errors.push('Please check the checkbox to proof you are not a robot.');
            }
        }
    }

    async onVerified(response) {
        this.errors.splice(0);
        this.verified = response;
        await this.submit();
    }

    onExpired() {
        this.verified = null;
        this.errors.push('Please check the checkbox to proof you are not a robot.');
    }
}
