import * as environment from '@config/environment.json';
import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationRules } from 'aurelia-validation';
import { IdentityRepository } from 'services/identity-repository';

@autoinject()
export class ForgotPassword {
    public errors = [];
    public success = [];

    public email: string = '';

    private isBusy = false;

    constructor(private readonly router: Router, private readonly controller: BootstrapValidationController, private readonly repository: IdentityRepository) {

        ValidationRules
            .ensure('email').required().email()
            .on(this);
    }

    public async submit() {
        const validate = await this.controller.validate();

        if (validate.valid) {
            this.isBusy = true;
            const result = await this.repository.sendPasswordResetToken(this.email);
            this.isBusy = false;

            if (!result.ok) {
                this.success.splice(0);
                this.errors.splice(0);
                result.errors.forEach((e) => {
                    const description = e.description;
                    this.errors.push(description);
                });
            } else {
                return this.router.navigateToRoute('forgotPasswordConfirmation');
            }
        }
    }
}
