import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { autoinject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { AuthService } from 'services/auth-service';

@autoinject()
export class Profile {
    public errors = [];

    public password: string = '';
    public confirmPassword: string = '';

    constructor(private readonly controller: BootstrapValidationController, private readonly authService: AuthService) {

        ValidationRules
            .ensure('userName').required()
            .ensure('email').required().email()
            .on(this);
    }

    public get userName() {
        return this.authService.currentUser().userName;
    }

    public get email() {
        return this.authService.currentUser().email;
    }

    public async submit() {
        //
    }
}
